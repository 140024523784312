import React from "react";
import bgLogo from "./../../../images/logo-bg.png";
import bgLogoWhite from "./../../../images/logo-white-bg.png";
import { useSelector } from "react-redux";

const LoginInnerWrapper = ({ children }) => {
    return (
        <div className="login-wrapper">
            {children}
        </div>
    );
};

export default LoginInnerWrapper;