const HeaderLeft=()=>{
    return (
        <div className="main-text-logo">
              <h1>
                PRIVATE<span>COURT</span>
              </h1>
              <p>
                The International Court <span>of</span>{" "}
                <strong>arbitration</strong>
              </p>
            </div>
    )
}
export default HeaderLeft