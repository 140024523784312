import {createSlice} from "@reduxjs/toolkit";

const initialState={
    email:"",
}

export const emailSlice=createSlice({
    name:"email",
    initialState,
    reducers:{
        changeEmail:(state, action)=>{
            state.email=action.payload;
        }
    }
});

export const {changeEmail}=emailSlice.actions;

export default emailSlice.reducer;