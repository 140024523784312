import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import auth from "../services/authService";

const Logout=()=>{
    const navigate = useNavigate();
    useEffect(()=>{
        auth.logout();
        navigate("/login");
    },[navigate]);
    
    return null
   
}

export default Logout;