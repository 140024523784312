const keyName1="language";

function saveLang(obj){
    if(obj){
    let str=JSON.stringify(obj);
    localStorage.setItem(keyName1, str);
    }
}

function removeLang(){
    localStorage.removeItem(keyName1);
}

function getLang(){
    let str=localStorage.getItem(keyName1);
    let obj=str?JSON.parse(str):null;
    return obj;
}

export default{
    getLang,
    removeLang,
    saveLang
}